import OrderService from "../../services/OrderService";
import CustomerService from "../../services/CustomerService";
import download from "downloadjs";

export default {
  namespaced: true, // all the state, mutations, actions, getters in this module is unique to this store module
  state() {
    return {
      Orders: [],
      Cart: null,
      OpenOrders: 0,
      CartTotal: 0,
      lastOrderSended: {},
      isRangeDatesOptionClicked: true,
      balances: null,
      tax: 17,
    };
  },
  mutations: {
    setTax(state, tax) {
      state.tax = tax;
    },
    setBalances(state, balances) {
      state.balances = balances;
    },
    toggleIsRangeDatesOptionClicked(state) {
      state.isRangeDatesOptionClicked = !state.isRangeDatesOptionClicked;
    },
    setOrders(state, userObj) {
      state.Orders = userObj;
      this.commit("Orders/setOpenOrders");
    },
    setLastOrderSended(state, orderObj) {
      console.log(orderObj);
      state.lastOrderSended = { ...orderObj };
    },
    setOrder(state, order) {
      const index = state.Orders.findIndex((ord) => ord.ord === order.ord);
      if (typeof index !== "undefined") {
        state.Orders[index] = order;
      } else {
        state.Orders.push(order);
      }
      this.commit("Orders/setOpenOrders");
    },
    setOrderLine(state, newOrderLine) {
      const index = state.Orders.findIndex(
        (ord) => ord.ord === newOrderLine.ord
      );
      console.log("the index");
      console.log(index);

      if (typeof index !== "undefined") {
        //state.Orders[index].OrderLines[newOrderLine.line - 1] = newOrderLine;
        const updatedLines = state.Orders[index].OrderLines.map((line) => {
          if (line.ordi === newOrderLine.ordi) {
            // Return new object
            return {
              ...newOrderLine,
            };
          }
          // Otherwise return original
          return line;
        });
        // Set state to new array
        state.Orders[index].OrderLines = updatedLines;
      }
      this.commit("Orders/setOpenOrders");
    },
    removeLine(state, data) {
      if (data.ord > 0) {
        const index = state.Orders.findIndex((ord) => ord.ord === data.ord);
        const ordiIndex = state.Orders[index].OrderLines.findIndex(
          (line) => line.ordi === data.ordi
        );
        state.Orders[index].OrderLines.splice(ordiIndex, 1);
        this.commit("Orders/setOpenOrders");
      } else {
        const cartIndex = state.Cart.OrderLines.findIndex(
          (line) => line.ordi === data.ordi
        );
        state.Cart.OrderLines.splice(cartIndex, 1);
        this.commit("Orders/setCartTotal");
      }
    },
    setCartOrderLine(state, newOrderLine) {
      const cartIndex = state.Cart.OrderLines.findIndex(
        (line) => line.ordi === newOrderLine.ordi
      );
      if (cartIndex >= 0) {
        state.Cart.OrderLines[cartIndex] = newOrderLine;
      } else {
        state.Cart.OrderLines.push(newOrderLine);
      }
      this.commit("Orders/setCartTotal");
    },
    setCart(state, userObj) {
      state.Cart = userObj;
      this.commit("Orders/setCartTotal");
    },
    emptyCart(state) {
      state.Cart = { ...state.Cart, OrderLines: [] };
      state.CartTotal = 0;
    },
    setCartTotal(state) {
      let total = 0;
      state.Cart.OrderLines.forEach((line) => {
        total += line.quant;
      });
      state.CartTotal = total;
    },
    setOpenOrders(state) {
      let total = 0;
      state.Orders.forEach((order) => {
        if (order.Status.activeorder) {
          total += 1;
        }
      });
      state.OpenOrders = total;
    },
  },
  actions: {
    setTax(context, tax) {
      context.commit("setTax", tax);
    },
    async generateLinkPaymment(context, obj) {
      const { totalAmount, senddate, notes, tax } = obj;
      try {
        const response = await OrderService.generateLinkPaymment({
          totalAmount,
          senddate,
          notes,
          tax,
        });
        return response.data;
      } catch (error) {
        console.log("error updateing order:", error);
        const errorData = error.response?.data;
        const errorMsg =
          errorData.message ?? "ארעה שגיאה, מומלץ לרענן את הדף ולנסות שוב";
        context.dispatch(
          "stopLoading",
          {
            status: "error",
            message: errorMsg,
          },
          { root: true }
        );
        return error.response.data;
      }
    },
    async fetchCustomerBalances(context) {
      const balances = await CustomerService.getCustomerBalances();
      context.commit("setBalances", balances.data);
    },
    async getCustomerBalancesFromHasahsevet(context) {
      try {
        const response =
          await CustomerService.getCustomerBalancesFromHasahsevet();
        context.commit("setBalances", response.data.balances);
        if (response.data.areOrdersUpdated) {
          const ordersa = await OrderService.getOrders({});
          await this.dispatch("Orders/updateOrders", ordersa.data.orders);
          await this.dispatch("Orders/updateCart", ordersa.data.cart);
        }
      } catch (expection) {
        console.log(expection);
      }
    },
    async printDelivery(context, docNum) {
      this.dispatch("startLoading", null, { root: true });
      try {
        let userLang = context.rootState.AuthModule.user.language;

        let res =
          userLang === "he"
            ? await OrderService.printDelivery(docNum)
            : await OrderService.printDeliveryEnglish(docNum);

        if (res) {
          context.dispatch(
            "stopLoading",
            { status: "success", message: "הורדה הושלמה" },
            { root: true }
          );
        }

        download(
          res.data,
          `https://app-prod.nirotek.com/netfiles/1450130p1364427430.pdf`,
          res.headers["content-type"]
        );

        return res;
      } catch (err) {
        console.log("error printOrder from Orders.js:", err);
        context.dispatch(
          "stopLoading",
          { status: "error", message: "שגיאה בניסיון הצגת הזמנה" },
          { root: true }
        );
      }
    },
    async printOrder(context, ordName) {
      this.dispatch("startLoading", null, { root: true });
      try {
        let res = await OrderService.printOrder(ordName);
        if (res) {
          context.dispatch(
            "stopLoading",
            { status: "success", message: "מציג הזמנה" },
            { root: true }
          );
        }

        download(
          res.data,
          `https://app-prod.nirotek.com/netfiles/1450130p1364427430.pdf`,
          res.headers["content-type"]
        );

        return res;
      } catch (err) {
        console.log("error printOrder from Orders.js:", err);
        context.dispatch(
          "stopLoading",
          { status: "error", message: "שגיאה בניסיון הצגת הזמנה" },
          { root: true }
        );
      }
    },
    async clearCart(context) {
      try {
        await OrderService.clearCart();
        context.commit("emptyCart");
      } catch (error) {
        console.log(error);
        context.dispatch(
          "stopLoading",
          {
            status: "error",
            message: "ארעה שגיאה, מומלץ לרענן את הדף ולנסות שוב",
          },
          { root: true }
        );
      }
    },
    async cloneOrderToCart(context, obj) {
      this.dispatch("startLoading", null, { root: true });
      try {
        const response = await OrderService.cloneOrderToCart(obj);
        const ordersa = await OrderService.getOrders({});
        if (response.status === 200) {
          await context.dispatch("updateCart", ordersa.data.cart);
          await context.dispatch("updateOrders", ordersa.data.orders);
        }
        this.dispatch("stopLoading", null, { root: true });
        return response;
      } catch (expection) {
        console.log(expection);
        context.dispatch(
          "stopLoading",
          {
            status: "error",
            message: "ארעה שגיאה, מומלץ לרענן את הדף ולנסות שוב",
          },
          { root: true }
        );
        return expection;
      }
    },
    async updateOrderLine(context, orderLine) {
      console.log("hila");
      console.log(orderLine);
      // this.dispatch("startLoading", null, { root: true });

      try {
        let res = await OrderService.updateOrderLine(orderLine);
        console.log("oooooooooooo");
        console.log(res.data);
        // console.log('bagi');
        // console.log(res.data);
        console.log("ttttttesssssstttttt");
        console.log(res.data.ord);
        if (res.data.ord < 0) {
          console.log("caaarrrrrrrrrrttttttttttt");
          context.commit("setCartOrderLine", res.data);
        } else {
          console.log("orddeeeeeeeeerrrrrrr");
          context.commit("setOrderLine", res.data);
        }

        console.log("the new order line");
        console.log(res.data);
        return true;
      } catch (err) {
        console.log(err);
        context.dispatch(
          "stopLoading",
          {
            status: "error",
            message: "ארעה שגיאה, מומלץ לרענן את הדף ולנסות שוב",
          },
          { root: true }
        );
        return false;
      }
      // מפעיל לאודר
      // פונה באיפיי לסרויס
      // מקבל תשובה שך אורדר לין בתמורה
      // ועושה פוש לאורדר הרלוונטי
    },

    updateCart(context, data) {
      context.commit("setCart", data);
    },

    updateOrders(context, data) {
      // sort to order from desc date order
      data = data
        .slice()
        .sort((ordera, orderb) => orderb.duedate.localeCompare(ordera.duedate));
      context.commit("setOrders", data);
    },
    async sendOrder(context, order) {
      this.dispatch("startLoading", null, { root: true });

      try {
        const response = await OrderService.sendOrder(order);
        context.commit("setLastOrderSended", order);
        context.dispatch("stopLoading", null, { root: true });
        return response;
      } catch (error) {
        console.log("error updateing order:", error);
        const errorData = error.response.data;
        const errorMsg =
          errorData.message ?? "ארעה שגיאה, מומלץ לרענן את הדף ולנסות שוב";
        context.dispatch(
          "stopLoading",
          {
            status: "error",
            message: errorMsg,
          },
          { root: true }
        );
        const errorResponse = {
          status: error.response.status,
          ...errorData,
        };
        return errorResponse;
      }
    },
    async sendOrderToPriority(context, order) {
      this.dispatch("startLoading", null, { root: true });

      try {
        const response = (await OrderService.sendOrderToPriority(order)).data;
        context.dispatch("stopLoading", null, { root: true });
        return response;
      } catch (error) {
        console.log("error updateing order:", error);
        context.dispatch(
          "stopLoading",
          {
            status: "error",
            message: "ארעה שגיאה, מומלץ לרענן את הדף ולנסות שוב",
          },
          { root: true }
        );
        return error;
      }

      // console.log('The Order --->', response);
    },
    // async createOrder(context, theOrder) {
    //     console.log('the order from createOrder!!!');
    //     console.log('----------------');
    //     console.log(theOrder);
    //     console.log('----------------');
    //     try {
    //         const results = (await OrderService.createOrderInDB(theOrder))
    //         context.commit('setOrder', results);
    //         context.commit('setOpenOrders');
    //         console.log(results);
    //     } catch (error) {
    //         console.log("error create order in DB:", error);
    //     }

    // },

    async updateOrder(context, data) {
      this.dispatch("startLoading", null, { root: true });

      try {
        console.log("-------");
        console.log(data);
        const results = (await OrderService.updateOrder(data)).data;
        // console.log('yeee:', results)
        if (results.ord > 0) {
          context.commit("setOrder", results);
          context.commit("setOpenOrders");
        } else {
          context.commit("setCart", results);
          context.commit("setCartTotal");
        }
        context.dispatch(
          "stopLoading",
          { status: "success", message: "השינויים נשמרו בהצלחה" },
          { root: true }
        );
      } catch (error) {
        console.log("error updateing order:", error);
        context.dispatch(
          "stopLoading",
          { status: "error", message: "ההזמנה נשלחה לביצוע אך לא עודכנה באתר" },
          { root: true }
        );
      }
    },

    async removeOrderLine({ commit }, data) {
      this.dispatch("startLoading", null, { root: true });
      try {
        await OrderService.removeOrderLine(data);
        commit("removeLine", data);
        this.dispatch("stopLoading", null, { root: true });
      } catch (err) {
        const errorData = err.response.data;
        let errorMsg =
          errorData.error ?? "ארעה שגיאה, מומלץ לרענן את הדף ולנסות שוב";
        console.log("error remove line:" + err);
        this.dispatch(
          "stopLoading",
          { status: "error", message: errorMsg },
          { root: true }
        );
      }
    },
  },
  getters: {
    getCustomerBalances(state) {
      return state.balances;
    },
    getIsRangeDatesOptionClicked(state) {
      return state.isRangeDatesOptionClicked;
    },
    getOrders(state) {
      return state.Orders;
    },
    getUserOrders: (state) => (userPhone) => {
      return state.Orders.find((order) => order?.User?.phone === userPhone);
    },
    getOrder: (state) => (ordername) => {
      if (ordername == "cart") {
        return state.Cart;
      } else {
        for (let order in state.Orders)
          if (state.Orders[order].ordname == ordername)
            return state.Orders[order];
      }
    },
    getCart(state) {
      return state.Cart;
    },
    getCartTotal(state) {
      return state.CartTotal;
    },
    getOpenOrders(state) {
      return state.OpenOrders;
    },
    getOrderStatuses(state) {
      let statusList = {};
      for (let order of state.Orders) {
        if (typeof statusList[order.Status.status] === "undefined")
          statusList[order.Status.status] = {
            id: order.Status.status,
            name: order.Status.statusname,
            icon: order.Status.icon,
            count: 0,
            enName: order.Status.estatusname,
          };
        // let statusObj = { id: order.Status.status, name: order.Status.statusname, icon:order.Status.icon }
        // statusList.push(statusObj);
        statusList[order.Status.status].count++;
      }
      // statusList = [...new Map(statusList.map(item => [JSON.stringify(item), item])).values()];
      return statusList;
    },
    getOrderAddresses(state) {
      let addressList = [];
      for (let order of state.Orders) {
        if (order.Site) {
          addressList.push(order.Site.description);
        }
      }
      addressList = [...new Set(addressList)];

      return addressList;
    },
    getOrderTotalPrice(state, getters) {
      return (ordNumber) => {
        let total = 0;
        let order = state.Orders.find((ord) => ord.ord == ordNumber);
        if (order) {
          total = order.OrderLines.reduce((total, line) => {
            return total + line.partPrice * line.quant;
          }, 0);
        }
        return getters["getCalculatedTax"](order.tax, total);
      };
    },
    getCartOrderLinesNumber(state) {
      return state.Cart ? state.Cart.OrderLines.length : 0;
    },
    getLastOrderSended(state) {
      return state.lastOrderSended;
    },
    getOpenOrdersTotalPrice(state, getters) {
      let totalPrice = 0;
      if (state.Orders.length > 0) {
        for (let order of state.Orders) {
          if (
            order.OrderLines.length > 0 &&
            order.status === 2 // ססטוס נקלטה
          ) {
            let currenctTotalPrice = 0;
            currenctTotalPrice = order.OrderLines.reduce((total, line) => {
              return total + line.partPrice * line.quant;
            }, 0);
            // const debt = order.debtBalance ? order.debtBalance : 0;
            totalPrice += currenctTotalPrice;
          }
        }
      }

      return getters["getCalculatedTax"](state.tax, totalPrice);
    },
    getCartTotalPrice(state) {
      let total = 0;

      if (state.Cart) {
        total = state.Cart.OrderLines.reduce((total, line) => {
          return total + line.partPrice * line.quant;
        }, 0);
      }

      return total;
    },
    getCartTotalPriceWithTax(state, getters) {
      return getters["getCalculatedTax"](
        state.tax,
        getters["getCartTotalPrice"]
      );
    },
    getTax(state) {
      return state.tax;
    },
    getFixedPrice() {
      return (price) => {
        if (!Number.isInteger(price)) {
          return parseFloat(parseFloat(price).toFixed(2));
        }
        return price;
      };
    },
    getCalculatedTax() {
      return (tax, total) => {
        return total * (1 + tax * 0.01);
      };
    },
    getCustomerDebtBalance(state, getters) {
      // if (state.balances.maxBalance <= 0) return 0;
      console.log(state.balances.balance);
      let openOrdersTotalPrice = parseFloat(getters["getOpenOrdersTotalPrice"]);
      openOrdersTotalPrice = parseFloat(openOrdersTotalPrice.toFixed(2));
      console.log(openOrdersTotalPrice);
      return state.balances.balance + openOrdersTotalPrice;
    },
    getTotalPriceWithDebtBalance(_, getters) {
      const customerDebtBalance = parseFloat(getters["getCustomerDebtBalance"]);
      // if (customerDebtBalance <= 0) return "0";
      let calculatedPrice =
        getters["getCartTotalPriceWithTax"] + customerDebtBalance;
      if (calculatedPrice < 0) return "0";
      // the user should not need to pay the order price

      return calculatedPrice;
    },
  },
};
